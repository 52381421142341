import { useState } from "react";
import { useSelector } from "react-redux";

import MenuDrawer from "./MenuDrawer";
import ViewButton from "../ViewButton";

const ViewMenuButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { cuisine, mealType, mealTime } = useSelector(
    (state) => state.orderSlice.order,
  );

  const isMenuEnabled = Boolean(cuisine && mealType && mealTime);

  const openDrawer = () => setIsOpen(true);

  if (!isMenuEnabled) return null;

  return (
    <div style={{ alignSelf: "end" }}>
      <ViewButton onClick={openDrawer}>Menus</ViewButton>
      <MenuDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default ViewMenuButton;
