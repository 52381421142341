import { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { hideLoader, showLoader } from "@/setup/loading";

import AddressSuggestions from "./AddressesSuggestions";
import SearchInput from "./SearchInput";

const ERROR_MESSAGE = "Error fetching details, Please proceed with pincode";

const AddressSearch = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");

  const handleFailure = () => {
    toast.warn(ERROR_MESSAGE);
    navigate("/", { replace: true });
  };

  const submitPincode = (pincode) => {
    localStorage.setItem("pincode", pincode);
    navigate("/", { replace: true });
  };

  const handleSelect = async (value) => {
    try {
      showLoader();
      const results = await geocodeByAddress(value);

      const pincode = results[0].address_components.find((component) =>
        component.types.includes("postal_code"),
      ).short_name;

      if (pincode) {
        submitPincode(pincode);
      } else {
        handleFailure();
      }
    } catch (error) {
      console.error(error);
      handleFailure();
    } finally {
      hideLoader();
    }
  };

  return (
    <div style={{ margin: "20px 0px" }}>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{ position: "relative" }}>
            <SearchInput
              getInputProps={getInputProps}
              value={address}
              setValue={setAddress}
            />
            <AddressSuggestions
              getSuggestionItemProps={getSuggestionItemProps}
              suggestions={suggestions}
            />
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default AddressSearch;
