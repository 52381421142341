const formatOrderPayload = (order) => {
  const address = order.address;
  return {
    pincode: order.pincode,
    kitchenId: order.kitchen._id,
    cuisine: order.cuisine,
    mealType: order.mealType,
    mealTime: order.mealTime,
    period: order.period,
    startDate: order.startDate,
    quantity: order.quantity,
    address: {
      fullName: address.fullName,
      mobile: address.mobile,
      building: address.building,
      area: address.area,
      landmark: address.landmark,
      district: address.district,
      state: address.state,
      country: address.country,
      pincode: address.pincode,
      latitude: address.latitude,
      longitude: address.longitude,
    },
    price: order.price,
  };
};

export default formatOrderPayload;
