import { useState } from "react";

import PriceBreakdownDrawer from "./PriceBreakdownDrawer";
import ViewButton from "../ViewButton";

const ViewPriceBreakdownButton = () => {
  const [open, setOpen] = useState(false);

  const openDrawer = () => setOpen(true);

  return (
    <>
      <ViewButton onClick={openDrawer} style={{ alignSelf: "end" }}>
        Price Breakdown
      </ViewButton>
      <PriceBreakdownDrawer isOpen={open} setIsOpen={setOpen} />
    </>
  );
};

export default ViewPriceBreakdownButton;
