import { lazy } from "react";

import AuthPage from "./pages/AuthPage";

const LoginWithOtpPage = lazy(() => import("./pages/LoginWithOtpPage"));
const ResetPasswordPage = lazy(() => import("./pages/ResetPasswordPage"));

const authRoutes = [
  {
    path: "auth",
    component: <AuthPage />,
    isProtected: false,
  },
  {
    path: "auth/login-with-otp",
    component: <LoginWithOtpPage />,
    isProtected: false,
  },
  {
    path: "auth/reset-password",
    component: <ResetPasswordPage />,
    isProtected: false,
  },
];

export default authRoutes;
