import { useDispatch, useSelector } from "react-redux";

import { useData } from "@/contexts/DataContext";
import { orderActions } from "@/store/order";

import Label from "../Label";
import RadioButtons from "../RadioButtons";

const SelectPeriod = () => {
  const dispatch = useDispatch();
  const { periodLabels } = useData();
  const { kitchen, period } = useSelector((state) => state.orderSlice.order);

  const setPeriod = (period) => {
    dispatch(orderActions.updateOrder({ period }));
  };

  const periodOptions = kitchen.configuration.periods.map((period) => {
    return {
      value: period,
      label: periodLabels[period],
    };
  });

  return (
    <div>
      <Label label="Select Period" />
      <RadioButtons
        options={periodOptions}
        selectedOption={period}
        setSelectedOption={setPeriod}
      />
    </div>
  );
};

export default SelectPeriod;
