import { useDispatch, useSelector } from "react-redux";

import Select from "@/components/Select";
import { orderActions } from "@/store/order";

const DeliveryPincode = () => {
  const dispatch = useDispatch();
  const { pincode, kitchen } = useSelector((state) => state.orderSlice.order);
  const { deliveryPincodes } = kitchen.configuration;

  const setPincode = (pincode) => {
    dispatch(orderActions.updateOrder({ pincode }));
  };

  return (
    <Select
      label={pincode ? "Delivery Pincode" : "Select Delivery Pincode"}
      options={deliveryPincodes.map((pincode) => {
        return {
          label: pincode,
          value: pincode,
        };
      })}
      value={pincode}
      onChange={setPincode}
      style={{ margin: "20px 0px" }}
    />
  );
};

export default DeliveryPincode;
