import { useNavigate } from "react-router-dom";

import logo from "@/assets/images/logo.png";

const Logo = () => {
  const navigate = useNavigate();
  return (
    <img
      src={logo}
      alt="Logo"
      onClick={() => {
        navigate("/");
      }}
      style={{
        cursor: "pointer",
        width: "90px",
      }}
    />
  );
};

export default Logo;
