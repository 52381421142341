import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Button, Typography } from "@mui/material";

const BottomButton = ({
  children,
  onClick,
  color = "primary",
  type = "submit",
  isArrowHidden = false,
  arrowDirection = "forward",
  disabled = false,
}) => {
  let arrow = null;
  if (!isArrowHidden) {
    if (arrowDirection === "forward") {
      arrow = <ArrowForwardIcon />;
    } else if (arrowDirection === "upward") {
      arrow = <ArrowUpwardIcon />;
    }
  }

  return (
    <div style={{ marginBottom: "80px" }}>
      <Button
        size="large"
        type={type}
        variant="contained"
        onClick={onClick}
        style={{
          position: "fixed",
          zIndex: 1,
          bottom: "0px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          margin: "0 auto",
        }}
        color={color}
        disabled={disabled}
        endIcon={arrow && arrow}
      >
        <Typography variant="subtitle1">{children}</Typography>
      </Button>
    </div>
  );
};

export default BottomButton;
