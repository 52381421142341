import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import api from "@/api";

import PincodeInput from "./PincodeFormInput";
import BottomButton from "../BottomButton";
import FormComponents from "../FormComponents";
import MapDrawer from "../MapDrawer";

const addressBuilder = [
  {
    type: "input",
    label: "Full name",
    state: "fullName",
    value: "",
  },
  {
    type: "mobile",
    label: "Mobile number",
    state: "mobile",
    value: "",
  },
  {
    type: "input",
    label: "Flat, House no., Building, Company, Apartment",
    state: "building",
    value: "",
  },
  {
    type: "input",
    label: "Area, Street, Sector, Village",
    state: "area",
    value: "",
  },
  {
    type: "input",
    label: "Landmark (optional)",
    state: "landmark",
    value: "",
  },
  {
    type: "input",
    label: "Town/City",
    state: "district",
    value: "",
  },
  {
    type: "input",
    label: "State",
    state: "state",
    value: "",
  },
  {
    type: "input",
    label: "Country",
    state: "country",
    value: "",
  },
];

const validationSchema = Yup.object({
  fullName: Yup.string()
    .transform((value) => value.trim())
    .required("Contact name is required"),
  mobile: Yup.string()
    .transform((value) => value.trim())
    .required("Contact number is required")
    .matches(/^\d{10}$/, "Contact number must be 10 digits"),
  building: Yup.string()
    .transform((value) => value.trim())
    .required("Building information is required"),
  area: Yup.string()
    .transform((value) => value.trim())
    .required("Area information is required"),
  landmark: Yup.string()
    .transform((value) => value.trim())
    .notRequired(),
  district: Yup.string()
    .transform((value) => value.trim())
    .required("Town/City is required"),
  state: Yup.string()
    .transform((value) => value.trim())
    .required("State is required"),
  country: Yup.string()
    .transform((value) => value.trim())
    .required("Country is required"),
  pincode: Yup.string()
    .transform((value) => value.trim())
    .required("Pincode is required")
    .matches(/^\d{6}$/, "Pincode must be 6 digits"),
});

const constructAddressString = (address) => {
  const { building, area, landmark, district, state, country, pincode } =
    address;
  if (landmark) {
    const addressString = `${building}, ${area}, ${landmark}, ${district}, ${state}, ${country}, ${pincode}`;
    return addressString;
  } else {
    const addressString = `${building}, ${area}, ${district}, ${state}, ${country}, ${pincode}`;
    return addressString;
  }
};

const Address = ({
  initialAddress,
  submitHandler,
  disablePincode,
  btnName = "Continue",
  btnColor = "primary",
}) => {
  const [addressFormBuilder, setAddressFormBuilder] = useState(addressBuilder);
  const [pincodeDetails, setPincodeDetails] = useState(null);
  const [isMapDrawerOpen, setIsMapDrawerOpen] = useState(false);
  const [initialAddressString, setInitialAddressString] = useState("");
  const [currentAddressString, setCurrentAddressString] = useState("");
  const [isAddressChanged, setIsAddressChanged] = useState(false);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      mobile: "",
      building: "",
      area: "",
      landmark: "",
      district: "",
      state: "",
      country: "",
      pincode: "",
      latitude: "",
      longitude: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (!pincodeDetails) {
        return toast.error("Please enter a valid pincode");
      }
      setIsMapDrawerOpen(true);
    },
  });

  useEffect(() => {
    const getPincodeDetails = async () => {
      try {
        const pincodeDetails = await api.pincode.getDetails({
          pincode: formik.values.pincode,
        });
        setPincodeDetails(pincodeDetails);
      } catch (error) {
        console.error(error);
      }
    };

    if (formik.values.pincode.length === 6) {
      getPincodeDetails();
    }
  }, [formik.values.pincode]);

  useEffect(() => {
    if (pincodeDetails) {
      const { district, state, country } = pincodeDetails;
      formik.setFieldValue("district", district || "");
      formik.setFieldValue("state", state || "");
      formik.setFieldValue("country", country || "India");

      setAddressFormBuilder((prev) => {
        const updatedFormBuilder = [...prev];
        updatedFormBuilder[5].disabled = !!district;
        updatedFormBuilder[6].disabled = !!state;
        updatedFormBuilder[7].disabled = true;

        return updatedFormBuilder;
      });
    } else {
      formik.setFieldValue("district", "");
      formik.setFieldValue("state", "");
      formik.setFieldValue("country", "India");
      setAddressFormBuilder((prev) => {
        const updatedFormBuilder = [...prev];
        updatedFormBuilder[5].disabled = false;
        updatedFormBuilder[6].disabled = false;
        updatedFormBuilder[7].disabled = true;
        return updatedFormBuilder;
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pincodeDetails]);

  useEffect(() => {
    if (initialAddress) {
      formik.setValues({
        ...formik.values,
        ...initialAddress,
      });
      const addressString = constructAddressString(initialAddress);
      setInitialAddressString(addressString);
      setCurrentAddressString(addressString);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAddress]);

  useEffect(() => {
    setCurrentAddressString(constructAddressString(formik.values));
  }, [formik.values]);

  useEffect(() => {
    if (initialAddressString === currentAddressString) {
      setIsAddressChanged(false);
    } else {
      setIsAddressChanged(true);
    }
  }, [initialAddressString, currentAddressString]);

  return (
    <>
      <form onSubmit={formik.handleSubmit} style={{ marginBottom: "60px" }}>
        <FormComponents data={addressFormBuilder} formik={formik} />
        <PincodeInput formik={formik} disabled={disablePincode} />
        <BottomButton
          type="submit"
          arrowDirection="upward"
          color={formik.isValid ? "success" : "primary"}
        >
          Confirm location on map
        </BottomButton>
      </form>
      <MapDrawer
        isEditable={true}
        isOpen={isMapDrawerOpen}
        setIsOpen={setIsMapDrawerOpen}
        address={formik.values}
        useLatLng={!isAddressChanged}
        btnName={btnName}
        btnColor={btnColor}
        btnClickHandler={(latLng) =>
          submitHandler({
            ...formik.values,
            latitude: latLng.lat,
            longitude: latLng.lng,
          })
        }
      />
    </>
  );
};

export default Address;
