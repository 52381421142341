import { useState } from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { IconButton } from "@mui/material";

import PopupMenu from "./PopupMenu";

const UserAccountIcon = () => {
  const [popupMenuAnchorEl, setPopupMenuAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setPopupMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setPopupMenuAnchorEl(null);
  };

  return (
    <>
      <div onClick={handleMenuOpen}>
        <IconButton color="inherit" onClick={handleMenuOpen}>
          <AccountCircle />
        </IconButton>
      </div>
      <PopupMenu
        anchorEl={popupMenuAnchorEl}
        handleMenuClose={handleMenuClose}
      />
    </>
  );
};

export default UserAccountIcon;
