import { useState } from "react";
import { Container } from "@mui/material";
import { useSelector } from "react-redux";

import Header from "@/components/Header";
import OrDivider from "@/components/OrDivider";
import Title from "@/components/Title";

import {
  AddNewAddressButton,
  BottomButton,
  ExistingAddresses,
} from "./components";
import AddressPageGuard from "../../hoc/AddressPageGuard";

const SelectAddressPage = () => {
  const { address } = useSelector((state) => state.orderSlice.order);
  const [selectedAddress, setSelectedAddress] = useState(address);

  return (
    <AddressPageGuard>
      <Header />
      <Container>
        <Title>Address</Title>
        <AddNewAddressButton />
        <OrDivider />
        <ExistingAddresses
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
        />
        <BottomButton selectedAddress={selectedAddress} />
      </Container>
    </AddressPageGuard>
  );
};

export default SelectAddressPage;
