import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

const EndDate = () => {
  const { endDate } = useSelector((state) => state.orderSlice.order);

  if (!endDate) {
    return null;
  }

  return (
    <div>
      <Typography>Ends on</Typography>
      <Typography variant="body2" color={"green"}>
        {new Date(endDate).toDateString()}
      </Typography>
    </div>
  );
};

export default EndDate;
