import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import HomeIcon from "@mui/icons-material/Home";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LogoutIcon from "@mui/icons-material/Logout";
import PaymentIcon from "@mui/icons-material/Payment";
import PersonIcon from "@mui/icons-material/Person";
import RestoreIcon from "@mui/icons-material/Restore";
import { Backdrop, Menu, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth } from "@/contexts/AuthContext";

const PopupMenu = ({ anchorEl, handleMenuClose }) => {
  const authCtx = useAuth();
  const navigate = useNavigate();
  const isOpen = Boolean(anchorEl);

  const menuItems = [
    { icon: <ListAltIcon />, label: "Orders", path: "/orders" },
    {
      icon: <DeliveryDiningIcon />,
      label: "Completed Deliveries",
      path: "/deliveries/completed",
    },
    { icon: <PaymentIcon />, label: "Payments", path: "/payments" },
    { icon: <RestoreIcon />, label: "Refunds", path: "/refunds" },
    { icon: <HomeIcon />, label: "Addresses", path: "/addresses" },
    { icon: <PersonIcon />, label: "Profile", path: "/profile" },
    {
      icon: <LogoutIcon />,
      label: "Logout",
      action: () => {
        authCtx.logout();
        toast.success("Logged out successfully!");
      },
    },
  ];

  return (
    <>
      <Backdrop open={isOpen} onClick={handleMenuClose} style={{ zIndex: 2 }} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              if (item.path) {
                navigate(item.path);
              }
              if (item.action) {
                item.action();
              }
              handleMenuClose();
            }}
          >
            {item.icon}
            <Typography style={{ marginLeft: "10px" }}>{item.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default PopupMenu;
