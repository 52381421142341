import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import BottomButtonCommon from "@/components/BottomButton";
import { orderActions } from "@/store/order";

const BottomButton = ({ selectedAddress }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const continueHandler = () => {
    if (selectedAddress?._id) {
      dispatch(
        orderActions.updateOrder({
          address: selectedAddress,
        }),
      );
      navigate(`/create-order/summary-and-payment`);
    } else {
      toast.error("Please select an address to continue");
    }
  };

  if (!selectedAddress?._id) {
    return (
      <BottomButtonCommon isArrowHidden onClick={continueHandler}>
        Select Address
      </BottomButtonCommon>
    );
  }

  return (
    <BottomButtonCommon color="success" onClick={continueHandler}>
      Summary & Payment
    </BottomButtonCommon>
  );
};

export default BottomButton;
