import { lazy } from "react";

const PaymentsPage = lazy(() => import("./pages/PaymentsPage"));

const paymentRoute = {
  path: "payments",
  component: <PaymentsPage />,
  isProtected: true,
};

export default paymentRoute;
