import axios from "axios";

let base = "/auth/";

// Signup
const triggerSignupOtp = async ({ name, email, password, passwordConfirm }) =>
  (
    await axios.post(base + "signup/triggerOtp", {
      name,
      email,
      password,
      passwordConfirm,
    })
  ).data;

const verifySignupOtp = async ({ email, otp }) =>
  (
    await axios.post(base + "signup/verifyOtp", {
      email,
      otp,
    })
  ).data;

// Google Signin
const googleSignin = async ({ tokenId }) =>
  (
    await axios.post(base + "googleSignin", {
      tokenId,
    })
  ).data;

// Login
const login = async ({ email, password }) =>
  (
    await axios.post(base + "login", {
      email,
      password,
    })
  ).data;

const triggerLoginOtp = async ({ email }) =>
  (
    await axios.post(base + "login/triggerOtp", {
      email,
    })
  ).data;

const verifyLoginOtp = async ({ email, otp }) =>
  (
    await axios.post(base + "login/verifyOtp", {
      email,
      otp,
    })
  ).data;

// Reset password
const triggerResetPasswordOtp = async ({ email, password, passwordConfirm }) =>
  (
    await axios.post(base + "resetPassword/triggerOtp", {
      email,
      password,
      passwordConfirm,
    })
  ).data;

const verifyResetPasswordOtp = async ({ email, otp }) =>
  (
    await axios.post(base + "resetPassword/verifyOtp", {
      email,
      otp,
    })
  ).data;

const auth = {
  triggerSignupOtp,
  verifySignupOtp,
  googleSignin,
  login,
  triggerLoginOtp,
  verifyLoginOtp,
  triggerResetPasswordOtp,
  verifyResetPasswordOtp,
};

export default auth;
