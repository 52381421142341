import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";

import api from "@/api";
import { orderActions } from "@/store/order";
import handleError from "@/utils/handleError";

const KitchenGuard = ({ children }) => {
  const { kitchenId } = useParams();
  const dispatch = useDispatch();
  const kitchen = useSelector((state) => state.orderSlice.order.kitchen);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchKitchen = async () => {
      try {
        setLoading(true);
        const response = await api.kitchens.get({
          kitchenId,
        });
        const { kitchen } = response.data;
        dispatch(orderActions.updateOrder({ kitchen }));
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    };
    if (!kitchen || kitchen._id !== kitchenId) {
      fetchKitchen();
    } else {
      setLoading(false);
    }
  }, [dispatch, kitchen, kitchenId]);

  if (loading) {
    return null;
  }

  if (!kitchen._id) {
    return <Navigate to="/" />;
  }

  return children;
};

export default KitchenGuard;
