import axios from "axios";

import getAll from "@/utils/getAll";

let base = "/payments/";

const getAllPayments = getAll(base);

const getPayment = async ({ paymentId }) =>
  (await axios.get(base + paymentId)).data;

const payments = {
  getAllPayments,
  getPayment,
};

export default payments;
