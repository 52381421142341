import axios from "axios";

let base = "/users/";

const getMe = async () => (await axios.get(base + "me")).data;

const users = {
  getMe,
};

export default users;
