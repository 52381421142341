import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Address from "@/components/Address";
import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import { orderActions } from "@/store/order";
import handleError from "@/utils/handleError";

import AddressPageGuard from "../hoc/AddressPageGuard";

const AddAddressPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pincode } = useSelector((state) => state.orderSlice.order);

  const submitHandler = async (addressData) => {
    try {
      const {
        data: { address },
      } = await api.addresses.create(addressData);
      dispatch(
        orderActions.updateOrder({
          address,
        }),
      );
      navigate("/create-order/summary-and-payment", {
        replace: true,
      });
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <AddressPageGuard>
      <Header />
      <Container>
        <Title>Add Address</Title>
        <Address
          initialAddress={{
            pincode,
          }}
          submitHandler={submitHandler}
          disablePincode
          btnName="Summary & Payment"
          btnColor="success"
        />
      </Container>
    </AddressPageGuard>
  );
};

export default AddAddressPage;
