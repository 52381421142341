import { Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";

const DeliveryDays = ({ deliveryDays }) => (
  <div>
    <Typography variant="body2" gutterBottom>
      Delivering on :
    </Typography>
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {deliveryDays.map((day, idx) => (
        <Typography variant="caption" style={{ marginRight: "5px" }} key={day}>
          {capitalize(day)}
          {idx < deliveryDays.length - 1 ? "," : ""}
        </Typography>
      ))}
    </div>
  </div>
);

export default DeliveryDays;
