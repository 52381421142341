import AddAddressPage from "./pages/AddAddressPage";
import KitchenPage from "./pages/KitchenPage";
import KitchensPage from "./pages/KitchensPage";
import SelectAddressPage from "./pages/SelectAddressPage";
import SummaryAndPaymentPage from "./pages/SummaryAndPaymentPage";

const createOrderRoutes = [
  {
    path: "kitchens",
    component: <KitchensPage />,
    isProtected: false,
  },
  {
    path: "kitchens/:kitchenId",
    component: <KitchenPage />,
    isProtected: false,
  },
  {
    path: "create-order/select-address",
    component: <SelectAddressPage />,
    isProtected: true,
  },
  {
    path: "create-order/add-address",
    component: <AddAddressPage />,
    isProtected: true,
  },
  {
    path: "create-order/summary-and-payment",
    component: <SummaryAndPaymentPage />,
    isProtected: true,
  },
];

export default createOrderRoutes;
