import { Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  LocateMeButton,
  LocationPincodeInput,
  LocationSearchInput,
} from "@/components/Location";
import OrDivider from "@/components/OrDivider";

const SetupLocation = () => {
  const navigate = useNavigate();

  const submitPincodeHandler = (pincode) => {
    localStorage.setItem("pincode", pincode);
    navigate("/", { replace: true });
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography
          variant="subtitle2"
          textAlign="center"
          style={{ marginBottom: "20px" }}
        >
          SETUP YOUR LOCATION TO GET STARTED
        </Typography>
        <div style={{ marginBottom: "10px" }}>
          <LocationSearchInput />
        </div>
        <LocateMeButton submitPincode={submitPincodeHandler} />
        <OrDivider />
        <LocationPincodeInput submitPincode={submitPincodeHandler} />
      </CardContent>
    </Card>
  );
};

export default SetupLocation;
