import * as React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import SelectMUI from "@mui/material/Select";

const Select = ({
  label,
  options,
  value,
  onChange,
  style = { minWidth: 120 },
  clearable = false,
  smallSize,
}) => {
  const handleChange = (event) => {
    const newValue = event.target.value;
    onChange(newValue);
  };

  if (!style.minWidth) {
    style.minWidth = 120;
  }
  return (
    <div style={style}>
      <FormControl fullWidth size="small">
        <InputLabel>{label}</InputLabel>
        <SelectMUI
          value={value}
          label={label}
          onChange={handleChange}
          style={{ fontSize: smallSize && "12px" }}
        >
          {clearable && (
            <MenuItem key="none" value="">
              <em>None</em>
            </MenuItem>
          )}
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </SelectMUI>
      </FormControl>
    </div>
  );
};

export default Select;
