import { useCallback, useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { useData } from "@/contexts/DataContext";
import { orderActions } from "@/store/order";

import PriceDisplay from "./PriceDisplay";
import calculatePrice from "./utils/calculatePrice";
import ViewPriceBreakdownButton from "./ViewPriceBreakdownButton";

const Price = () => {
  const dispatch = useDispatch();
  const { periodToDaysMap } = useData();
  const {
    kitchen: { pricing },
    cuisine,
    mealType,
    mealTime,
    period,
    quantity,
    price,
  } = useSelector((state) => state.orderSlice.order);

  const setPrice = useCallback(
    (price) => {
      dispatch(orderActions.updateOrder({ price }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!cuisine || !mealType || !mealTime || !period || !quantity) {
      return;
    }

    const price = calculatePrice({
      cuisine,
      mealType,
      mealTime,
      period,
      periodInDays: periodToDaysMap[period],
      pricing,
      quantity,
    });

    setPrice(price);
  }, [
    cuisine,
    mealType,
    mealTime,
    period,
    quantity,
    pricing,
    setPrice,
    periodToDaysMap,
  ]);

  if (!price) {
    return null;
  }

  return (
    <Card
      style={{
        margin: "15px 0px",
      }}
    >
      <CardContent>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "10px",
          }}
        >
          <PriceDisplay price={price} />
          <ViewPriceBreakdownButton />
        </div>
      </CardContent>
    </Card>
  );
};

export default Price;
