import { createSlice } from "@reduxjs/toolkit";

const orderInitValue = {
  pincode: "",
  kitchen: {},
  cuisine: "",
  mealType: "",
  mealTime: "",
  quantity: 1,
  period: "",
  startDate: "",
  endDate: "",
  price: "",
  address: {},
  paymentMode: "",
  orderStatus: "",
};

const orderSlice = createSlice({
  name: "order",
  initialState: {
    order: orderInitValue,
    paymentGatewayDetails: {
      orderId: "",
    },
  },
  reducers: {
    // Order actions
    updateOrder: (state, action) => {
      state.order = { ...state.order, ...action.payload };
    },
    resetOrder: (state, action) => {
      if (action.payload) {
        state.order = { ...orderInitValue, ...action.payload };
      } else {
        state.order = { ...orderInitValue };
      }
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },

    // Payment gateway details actions
    setPaymentGatewayDetails: (state, action) => {
      state.paymentGatewayDetails = action.payload;
    },
    resetPaymentGatewayDetails: (state) => {
      state.paymentGatewayDetails = {};
    },
  },
});

export const { actions: orderActions, reducer: orderReducer } = orderSlice;
