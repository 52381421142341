import { useCallback, useEffect, useState } from "react";
import { Card, CardContent, Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import BottomDrawer from "@/components/BottomDrawer";
import api from "@/api";
import { useData } from "@/contexts/DataContext";
import handleError from "@/utils/handleError";

const MenuDrawer = ({ isOpen, setIsOpen }) => {
  const { cuisineLabels, mealTypeLabels, mealTimeLabels, dayLabels } =
    useData();
  const order = useSelector((state) => state.orderSlice.order);
  const [menuScheduleDays, setMenuScheduleDays] = useState([]);

  const {
    kitchen: { _id: kitchenId },
    cuisine,
    mealType,
    mealTime,
  } = order;

  const fetchMenus = useCallback(async () => {
    try {
      const {
        data: { menuScheduleDays },
      } = await api.menuSchedules.getMenuScheduleDays({
        kitchenId,
        cuisine,
        mealType,
        mealTime,
      });
      setMenuScheduleDays(menuScheduleDays);
    } catch (error) {
      handleError(error);
    }
  }, [kitchenId, cuisine, mealType, mealTime]);

  useEffect(() => {
    if (isOpen && cuisine && mealType && mealTime) {
      fetchMenus();
    }
  }, [isOpen, cuisine, mealType, mealTime, fetchMenus]);

  return (
    <BottomDrawer isOpen={isOpen} setIsOpen={setIsOpen} title="Menus">
      <Container style={{ paddingBottom: "30px" }}>
        <Typography
          variant="h6"
          textAlign={"center"}
          style={{ margin: "15px 0px" }}
        >
          {cuisineLabels[cuisine]} {mealTypeLabels[mealType]}{" "}
          {mealTimeLabels[mealTime]}
        </Typography>
        {menuScheduleDays.map(({ day, menuSchedule }) => {
          return (
            <Card key={day} style={{ margin: "10px 0px" }}>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  style={{ marginBottom: "10px", fontWeight: "bold" }}
                >
                  {dayLabels[day]}
                </Typography>
                <Typography variant="body2">
                  <strong>{menuSchedule.menu.title}</strong> :{" "}
                  {menuSchedule.menu.description}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </Container>
    </BottomDrawer>
  );
};

export default MenuDrawer;
