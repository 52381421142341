import { useEffect } from "react";
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "@/components/Header";
import NoListFoundText from "@/components/NoListFoundText";
import api from "@/api";
import { kitchensActions } from "@/store/kitchens";
import handleError from "@/utils/handleError";
import isValidPincode from "@/utils/isValidPincode";

import { KitchenCard, KitchenSearchAndFilters } from "./components";

const KitchensPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const localStoragePincode = localStorage.getItem("pincode");
  const { pincode: kitchensPincode, filteredKitchens } = useSelector(
    (state) => state.kitchensSlice,
  );

  useEffect(() => {
    const fetchKitchens = async () => {
      try {
        const {
          data: { kitchens },
        } = await api.kitchens.getAll({
          filters: {
            configuration: {
              deliveryPincodes: localStoragePincode,
            },
          },
        });

        dispatch(kitchensActions.setPincode(localStoragePincode));
        dispatch(kitchensActions.setKitchens(kitchens));
      } catch (error) {
        handleError(error);
      }
    };

    if (isValidPincode(localStoragePincode)) {
      if (localStoragePincode !== kitchensPincode) {
        fetchKitchens();
      }
    } else {
      navigate("/", { replace: true });
    }
  }, [dispatch, kitchensPincode, navigate, localStoragePincode]);

  return (
    <>
      <Header hideBackButton showPincode />
      <Container>
        <KitchenSearchAndFilters />
        {filteredKitchens.map((kitchen) => (
          <KitchenCard key={kitchen._id} kitchen={kitchen} />
        ))}
        {filteredKitchens.length === 0 && (
          <NoListFoundText>No kitchens found</NoListFoundText>
        )}
      </Container>
    </>
  );
};

export default KitchensPage;
