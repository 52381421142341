import addresses from "./addresses";
import auth from "./auth";
import data from "./data";
import deliveries from "./deliveries";
import googleMaps from "./googleMaps";
import kitchens from "./kitchens";
import menuSchedules from "./menuSchedules";
import orders from "./orders";
import payments from "./payments";
import pincode from "./pincode";
import ratings from "./ratings";
import refunds from "./refunds";
import users from "./users";

const api = {
  data,
  googleMaps,
  auth,
  users,
  pincode,
  kitchens,
  menuSchedules,
  addresses,
  orders,
  deliveries,
  ratings,
  refunds,
  payments,
};

export default api;
