const calculateEndDate = ({
  startDate,
  period,
  deliverableDays,
  dayToNumberMap,
  periodToDaysMap,
}) => {
  const deliverableDaysInNumbers = deliverableDays.map(
    (day) => dayToNumberMap[day],
  );

  startDate = new Date(startDate);

  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + periodToDaysMap[period] - 1);

  const currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    const dayOfWeek = currentDate.getDay();

    if (!deliverableDaysInNumbers.includes(dayOfWeek)) {
      endDate.setDate(endDate.getDate() + 1);
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return endDate;
};

module.exports = calculateEndDate;
