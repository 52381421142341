import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import BottomButtonCommon from "@/components/BottomButton";
import { useAuth } from "@/contexts/AuthContext";

const BottomButton = () => {
  const navigate = useNavigate();
  const authCtx = useAuth();
  const { pincode, cuisine, mealType, mealTime, period } = useSelector(
    (state) => state.orderSlice.order,
  );

  const isEverythingSelected = Boolean(
    pincode && cuisine && mealType && mealTime && period,
  );

  const proceedHandler = () => {
    if (!pincode) {
      return toast.error("Please select a delivery pincode");
    }
    if (!cuisine) {
      return toast.error("Please select a cuisine");
    }
    if (!mealType) {
      return toast.error("Please select a meal type");
    }
    if (!mealTime) {
      return toast.error("Please select a meal time");
    }
    if (!period) {
      return toast.error("Please select a period");
    }
    navigate("/create-order/select-address");
  };

  if (!isEverythingSelected) {
    return (
      <BottomButtonCommon isArrowHidden onClick={proceedHandler}>
        Please select all fields
      </BottomButtonCommon>
    );
  }

  return (
    <>
      {authCtx.isLoggedIn ? (
        <BottomButtonCommon color={"success"} onClick={proceedHandler}>
          Proceed to add Address
        </BottomButtonCommon>
      ) : (
        <BottomButtonCommon color="success" onClick={() => navigate("/auth")}>
          Sign in to add address
        </BottomButtonCommon>
      )}
    </>
  );
};

export default BottomButton;
