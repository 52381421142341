import styles from "./OrDivider.module.css";

const OrDivider = ({ margin }) => {
  return (
    <div className={styles.dividerContainer} style={{ margin }}>
      <div className={styles.horizontalLine}></div>
      <div className={styles.orText}>or</div>
      <div className={styles.horizontalLine}></div>
    </div>
  );
};

export default OrDivider;
