import { useData } from "@/contexts/DataContext";

const DataGuard = ({ children }) => {
  const data = useData();

  if (!data) {
    return null;
  }

  return children;
};

export default DataGuard;
