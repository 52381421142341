import Typography from "@mui/material/Typography";

const ColorLegend = ({ legendItems }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "10px",
        marginTop: "20px",
      }}
    >
      {legendItems.map((item, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: item.color,
              minWidth: "20px",
              minHeight: "20px",
            }}
          ></div>
          <Typography variant="caption" style={{ marginLeft: "5px" }}>
            {item.description}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default ColorLegend;
