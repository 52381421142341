import { Container } from "@mui/material";

import BottomDrawer from "@/components/BottomDrawer";

import PriceBreakdown from "./PriceBreakdown";

const PriceBreakdownDrawer = ({ isOpen, setIsOpen }) => {
  return (
    <BottomDrawer isOpen={isOpen} setIsOpen={setIsOpen} title="Price breakdown">
      <Container style={{ paddingTop: "20px" }}>
        <PriceBreakdown />
      </Container>
    </BottomDrawer>
  );
};

export default PriceBreakdownDrawer;
