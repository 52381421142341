import { Rating, Typography } from "@mui/material";

function KitchenRatings({ ratingValue, numRatings }) {
  const formattedRatingValue = parseFloat(ratingValue).toFixed(1);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Rating value={ratingValue} readOnly size="small" precision={0.5} />
      {!isNaN(formattedRatingValue) && (
        <Typography
          variant="body2"
          color="text.secondary"
          style={{ marginLeft: "4px" }}
        >
          {ratingValue !== 0 && formattedRatingValue}{" "}
          {numRatings !== 0 && `(${numRatings})`}
        </Typography>
      )}
    </div>
  );
}

export default KitchenRatings;
