import { lazy } from "react";

const ProfilePage = lazy(() => import("./pages/ProfilePage"));

const profileRoute = {
  path: "/profile",
  component: <ProfilePage />,
  isProtected: true,
};

export default profileRoute;
