import { Card, CardContent } from "@mui/material";

import Cuisine from "./Cuisine";
import MealTime from "./MealTime";
import MealType from "./MealType";
import Quantity from "./Quantity";
import ViewMenuButton from "./ViewMenuButton";

const Category = () => {
  return (
    <Card>
      <CardContent
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "20px 10px",
        }}
      >
        <Cuisine />
        <MealType />
        <div style={{ gridColumn: "span 2" }}>
          <MealTime />
        </div>
        <Quantity />
        <ViewMenuButton />
      </CardContent>
    </Card>
  );
};

export default Category;
