import { lazy } from "react";

const RefundsPage = lazy(() => import("./pages/RefundsPage"));

const refundRoute = {
  path: "refunds",
  component: <RefundsPage />,
  isProtected: true,
};

export default refundRoute;
