import { Typography } from "@mui/material";

const Tagline = ({ tagline }) => (
  <Typography
    variant="body2"
    style={{
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "100%",
    }}
    color={"gray"}
    gutterBottom
  >
    {tagline}
  </Typography>
);

export default Tagline;
