import { useCallback, useMemo, useState } from "react";

import BottomButton from "./BottomButton";
import BottomDrawer from "./BottomDrawer";
import Map from "./Map";

const MapDrawer = ({
  isEditable,
  isOpen,
  setIsOpen,
  address,
  useLatLng,
  btnName = "Continue",
  btnColor = "primary",
  btnClickHandler = () => {},
}) => {
  const [currentLatLng, setCurrentLatLng] = useState({});

  const {
    building,
    area,
    landmark,
    district,
    state,
    country,
    pincode,
    latitude,
    longitude,
  } = address;
  const addressString = `${building}, ${area}, ${landmark}, ${district}, ${state}, ${country}, ${pincode}`;
  const initialLatLng = useMemo(
    () => ({ lat: latitude, lng: longitude }),
    [latitude, longitude],
  );
  const title = isEditable ? "Drag & confirm your location" : "Location";

  const handleCurrentLatLng = useCallback((latLng) => {
    setCurrentLatLng(latLng);
  }, []);

  return (
    <BottomDrawer isOpen={isOpen} setIsOpen={setIsOpen} title={title}>
      <Map
        isEditable={isEditable}
        addressString={addressString}
        initialLatLng={initialLatLng}
        useInitialLatLng={useLatLng}
        handleLatLng={handleCurrentLatLng}
      />
      <BottomButton
        color={btnColor}
        onClick={() => btnClickHandler(currentLatLng)}
      >
        {btnName}
      </BottomButton>
    </BottomDrawer>
  );
};

export default MapDrawer;
