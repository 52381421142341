import { useState } from "react";
import { Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import FormComponents from "@/components/FormComponents";
import OtpVerificationDrawer from "@/components/OtpVerificationDrawer";
import api from "@/api";
import { useAuth } from "@/contexts/AuthContext";
import handleError from "@/utils/handleError";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password should be minimum 8 characters")
    .required("Password is required"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please Re-enter the password"),
});

const Signup = ({ loginClickHandler }) => {
  const navigate = useNavigate();
  const authCtx = useAuth();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const submitHandler = async (data) => {
    try {
      await api.auth.triggerSignupOtp(data);
      setIsDrawerOpen(true);
    } catch (err) {
      handleError(err);
      if (
        err?.response?.data?.message === "user exists already, please login"
      ) {
        loginClickHandler();
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
    },
    validationSchema,
    onSubmit: submitHandler,
  });

  const verifyOtp = async (otp) => {
    try {
      const res = await api.auth.verifySignupOtp({
        email: formik.values.email,
        otp,
      });
      authCtx.login({ token: res.token, user: res.data.user });
      setIsDrawerOpen(false);
      toast.success("Email verified and logged in successfully.");
      navigate(-1, { replace: true });
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormComponents
          formik={formik}
          data={[
            {
              type: "input",
              label: "Name",
              state: "name",
              value: "",
            },
            {
              type: "input",
              label: "Email",
              state: "email",
              value: "",
            },
            {
              type: "input",
              inputType: "password",
              label: "Password",
              state: "password",
              value: "",
            },
            {
              type: "input",
              inputType: "password",
              label: "Re-enter Password",
              state: "passwordConfirm",
              value: "",
            },
          ]}
        />
        <Button
          style={{ margin: "10px 0px" }}
          fullWidth
          variant="contained"
          type="submit"
        >
          Sign Up
        </Button>
        <div>
          <Typography variant="subtitle1">Already have an account?</Typography>
          <Typography
            variant="subtitle2"
            style={{ color: "#FF0000", cursor: "pointer", display: "inline" }}
            onClick={loginClickHandler}
          >
            Login Here
          </Typography>
        </div>
      </form>
      <OtpVerificationDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        submitHandler={verifyOtp}
        description="Please enter the 6 digit code sent to your email address to complete the signup process. Valid for 10 minutes."
      />
    </>
  );
};

export default Signup;
