import { Visibility } from "@mui/icons-material";
import { Button } from "@mui/material";

const ViewButton = ({ children, onClick, style }) => {
  return (
    <Button
      size="small"
      variant="outlined"
      color="success"
      fullWidth
      endIcon={<Visibility />}
      onClick={onClick}
      style={{ fontSize: 10, ...style }}
    >
      {children}
    </Button>
  );
};

export default ViewButton;
