import axios from "axios";

import getAll from "@/utils/getAll";

let base = "/kitchens/";

const getAllKitchens = getAll(base + "active");

const get = async ({ kitchenId }) =>
  (await axios.get(base + "active/" + kitchenId)).data;

const kitchens = {
  getAll: getAllKitchens,
  get,
};

export default kitchens;
