import { Card, CardContent } from "@mui/material";

import EndDate from "./EndDate";
import SelectPeriod from "./SelectPeriod";
import StartDate from "./StartDate";
import ViewCalendarButton from "./ViewCalendarButton";

const Period = () => {
  return (
    <Card style={{ margin: "15px 0px" }}>
      <CardContent
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "50%", paddingRight: "5px" }}>
          <SelectPeriod />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
            paddingLeft: "5px",
          }}
        >
          <StartDate />
          <EndDate />
          <ViewCalendarButton />
        </div>
      </CardContent>
    </Card>
  );
};

export default Period;
