import { Typography } from "@mui/material";

const Price = ({ price }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      border: "1px solid #e9e9eb",
      borderRadius: "6px",
      padding: "8px",
    }}
  >
    <Typography variant="caption">Starts at</Typography>
    <Typography variant="body1">₹{price}</Typography>
  </div>
);

export default Price;
