import StarIcon from "@mui/icons-material/Star";
import { Typography } from "@mui/material";

const Rating = ({ average, count }) => (
  <div
    style={{
      border: "1px solid #e9e9eb",
      borderRadius: "6px",
      textAlign: "center",
      padding: "8px",
      marginBottom: "8px",
    }}
  >
    <Typography
      variant="subtitle2"
      style={{
        paddingBottom: "10px",
        borderBottom: "1px solid #e9e9eb",
        fontWeight: "700",
        marginBottom: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "gold",
      }}
    >
      <StarIcon
        style={{
          fontSize: "1.2rem",
          marginRight: "2px",
        }}
      />
      {average ? average : "0"}
    </Typography>
    <Typography
      variant="body2"
      style={{
        fontSize: "11px",
        color: "#8b8d97",
        fontFamily:
          "ProximaNovaCondensedRegular, arial, Helvetica Neue, sans-serif",
        fontWeight: "600",
        textAlign: "center",
      }}
    >
      {count ? count : "No"} ratings
    </Typography>
  </div>
);

export default Rating;
