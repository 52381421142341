const { getTomorrowDate12AM } = require("@/utils/dateHelpers");

const generateStartDates = ({ deliverableDays, dayToNumberMap }) => {
  const deliverableDaysInNumbers = deliverableDays.map(
    (day) => dayToNumberMap[day],
  );

  const currentDate = new Date(getTomorrowDate12AM());
  const startDates = [];

  const count = 7;

  while (startDates.length < count) {
    if (deliverableDaysInNumbers.includes(currentDate.getDay())) {
      startDates.push(new Date(currentDate).toISOString());
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return startDates;
};

module.exports = generateStartDates;
