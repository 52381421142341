import { configureStore } from "@reduxjs/toolkit";

import { kitchensReducer } from "./kitchens";
import { orderReducer } from "./order";

export const store = configureStore({
  reducer: {
    kitchensSlice: kitchensReducer,
    orderSlice: orderReducer,
  },
});
