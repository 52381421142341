import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

const RadioButtons = ({ options, selectedOption, setSelectedOption }) => {
  return (
    <FormControl fullWidth>
      <RadioGroup
        value={selectedOption}
        onChange={(e) => setSelectedOption(e.target.value)}
      >
        {options.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item.value}
            control={<Radio />}
            label={<Typography variant="body2">{item.label}</Typography>}
            style={{ marginBottom: "2px" }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtons;
