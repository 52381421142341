import { Card, CardContent, Radio, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const SelectableAddressCard = ({ address, selected, onSelect }) => {
  const theme = useTheme();
  const {
    fullName,
    mobile,
    building,
    area,
    district,
    state,
    country,
    pincode,
  } = address;

  const addressString = `${building}, ${area}, ${district}, ${state}, ${country}, ${pincode}`;

  return (
    <Card
      style={{
        minWidth: 275,
        marginBottom: "15px",
        border: selected
          ? `2px solid ${theme.palette.primary.main}`
          : "2px solid transparent",
        cursor: "pointer",
      }}
      onClick={() => onSelect(address)}
    >
      <CardContent>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Radio checked={selected} />
          </div>
          <div>
            <Typography variant="h6">{fullName}</Typography>
            <Typography variant="subtitle1">{mobile}</Typography>
            <Typography variant="body2">{addressString}</Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default SelectableAddressCard;
