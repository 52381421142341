import axios from "axios";

const api = axios.create({
  baseURL: "https://maps.googleapis.com/maps/api/",
});

const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const geocodePincode = async ({ latitude, longitude }) => {
  const response = await api.get("/geocode/json", {
    params: {
      latlng: `${latitude},${longitude}`,
      key: googleMapApiKey,
    },
  });

  const { data } = response;

  if (!data || !data.results || data.results.length === 0) {
    return null;
  }

  const addressComponents = data.results[0].address_components;

  if (!addressComponents || addressComponents.length === 0) {
    return null;
  }

  const pincode = addressComponents.find((component) =>
    component.types.includes("postal_code"),
  )?.short_name;

  return pincode;
};

const googleMaps = {
  geocodePincode,
};

export default googleMaps;
