import { useDispatch, useSelector } from "react-redux";

import { useData } from "@/contexts/DataContext";
import { orderActions } from "@/store/order";

import Label from "../Label";
import RadioButtons from "../RadioButtons";

const MealType = () => {
  const dispatch = useDispatch();
  const { mealTypeLabels } = useData();
  const { kitchen, mealType } = useSelector((state) => state.orderSlice.order);

  const setMealType = (mealType) => {
    dispatch(orderActions.updateOrder({ mealType }));
  };

  const mealTypeOptions = kitchen.configuration.mealTypes.map((mealType) => {
    return {
      value: mealType,
      label: mealTypeLabels[mealType],
    };
  });

  return (
    <div>
      <Label label="Select MealType" />
      <RadioButtons
        options={mealTypeOptions}
        selectedOption={mealType}
        setSelectedOption={setMealType}
      />
    </div>
  );
};

export default MealType;
