import { Typography } from "@mui/material";

const NoListFoundText = ({ children, margin }) => {
  return (
    <Typography
      style={{
        color: "gray",
        textAlign: "center",
        margin: margin || "25px 0",
      }}
      variant="subtitle1"
    >
      {children}
    </Typography>
  );
};

export default NoListFoundText;
