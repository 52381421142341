import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const AddressPageGuard = ({ children }) => {
  const {
    pincode,
    kitchen,
    cuisine,
    mealType,
    mealTime,
    quantity,
    period,
    startDate,
    price,
  } = useSelector((state) => state.orderSlice.order);

  if (
    !pincode ||
    !kitchen._id ||
    !cuisine ||
    !mealType ||
    !mealTime ||
    !quantity ||
    !period ||
    !startDate ||
    !price
  ) {
    return <Navigate to="/" />;
  }

  return children;
};

export default AddressPageGuard;
