import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { orderActions } from "@/store/order";
import isValidPincode from "@/utils/isValidPincode";

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const storedPincode = localStorage.getItem("pincode");
    dispatch(orderActions.resetOrder());
    if (isValidPincode(storedPincode)) {
      navigate("/kitchens", { replace: true });
    } else {
      localStorage.removeItem("pincode");
      navigate("/welcome", { replace: true });
    }
  }, [dispatch, navigate]);

  return null;
};

export default HomePage;
