import { Container } from "@mui/material";

import Header from "@/components/Header";

import {
  BottomButton,
  Category,
  DeliveryPincode,
  KitchenDetails,
  Period,
  Price,
} from "./components";
import KitchenGuard from "./hoc/KitchenGuard";

const KitchenPage = () => {
  return (
    <KitchenGuard>
      <Header />
      <Container>
        <KitchenDetails />
        <DeliveryPincode />
        <Category />
        <Period />
        <Price />
        <BottomButton />
      </Container>
    </KitchenGuard>
  );
};

export default KitchenPage;
