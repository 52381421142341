const getMultiplier = (multipliers, key, value) =>
  multipliers.find((item) => item[key] === value).multiplier - 1;

const calculatePrice = ({
  pricing,
  cuisine,
  mealType,
  mealTime,
  period,
  periodInDays,
  quantity,
}) => {
  const {
    basePrice,
    cuisineMultipliers,
    mealTypeMultipliers,
    mealTimeMultipliers,
    periodMultipliers,
  } = pricing;

  const cuisineMultiplier = getMultiplier(
    cuisineMultipliers,
    "cuisine",
    cuisine,
  );
  const mealTypeMultiplier = getMultiplier(
    mealTypeMultipliers,
    "mealType",
    mealType,
  );
  const mealTimeMultiplier = getMultiplier(
    mealTimeMultipliers,
    "mealTime",
    mealTime,
  );
  const periodMultiplier = getMultiplier(periodMultipliers, "period", period);

  const cuisinePrice = Math.round(basePrice * cuisineMultiplier);
  const mealTypePrice = Math.round(basePrice * mealTypeMultiplier);
  const mealTimePrice = Math.round(basePrice * mealTimeMultiplier);
  const periodPrice = Math.round(basePrice * periodMultiplier);

  const pricePerMeal =
    basePrice + cuisinePrice + mealTypePrice + mealTimePrice + periodPrice;

  const quantityPrice = Math.round(pricePerMeal * quantity);

  const days = periodInDays;
  const daysPrice = Math.round(quantityPrice * days);

  const gstRate = 0.05;
  const gstAmount = Math.round(daysPrice * gstRate);

  const finalPrice = Math.round(daysPrice + gstAmount);

  return finalPrice;
};

export default calculatePrice;
