import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, TextField } from "@mui/material";

const SearchInput = ({ label, searchQuery, setSearchQuery, margin }) => {
  const clearValue = () => {
    setSearchQuery("");
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <TextField
      size="small"
      fullWidth
      label={label}
      value={searchQuery}
      onChange={handleSearchChange}
      InputProps={{
        endAdornment: (
          <>
            {searchQuery ? (
              <IconButton onClick={clearValue} size="small" edge="end">
                <ClearIcon />
              </IconButton>
            ) : (
              <IconButton size="small" edge="end">
                <SearchIcon />
              </IconButton>
            )}
          </>
        ),
      }}
      style={{ margin: margin && margin }}
    />
  );
};

export default SearchInput;
