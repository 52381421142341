import Input from "./Input";
import Mobile from "./Mobile";
import MultiSelect from "./MultiSelect";
import Select from "./Select";

const FormComponents = ({ data, formik, disabled }) => {
  return (
    <>
      {data.map((item, idx) => {
        switch (item.type) {
          case "input":
            return (
              <Input
                key={idx}
                formik={formik}
                label={item.label}
                state={item.state}
                inputType={item.inputType || "text"}
                disabled={item.disabled}
                multiline={item.multiline}
                rows={item.rows}
                restOfProps={item.restOfProps}
              />
            );
          case "select":
            return (
              <Select
                key={idx}
                formik={formik}
                label={item.label}
                state={item.state}
                options={item.options}
                disabled={item.disabled}
                restOfProps={item.restOfProps}
              />
            );
          case "mobile":
            return (
              <Mobile
                key={idx}
                formik={formik}
                label={item.label}
                state={item.state}
                disabled={item.disabled}
              />
            );
          case "multiSelect":
            return (
              <MultiSelect
                key={idx}
                formik={formik}
                label={item.label}
                state={item.state}
                options={item.options}
                disabled={item.disabled}
              />
            );
          default:
            return <></>;
        }
      })}
    </>
  );
};

export default FormComponents;
