import { useDispatch, useSelector } from "react-redux";

import { useData } from "@/contexts/DataContext";
import { orderActions } from "@/store/order";

import Label from "../Label";
import RadioButtons from "../RadioButtons";

const Cuisine = () => {
  const dispatch = useDispatch();
  const { cuisineLabels } = useData();
  const { kitchen, cuisine } = useSelector((state) => state.orderSlice.order);

  const setCuisine = (cuisine) => {
    dispatch(orderActions.updateOrder({ cuisine }));
  };

  const cuisineOptions = kitchen.configuration.cuisines.map((cuisine) => {
    return {
      value: cuisine,
      label: cuisineLabels[cuisine],
    };
  });

  return (
    <div>
      <Label label="Select Cuisine" />
      <RadioButtons
        options={cuisineOptions}
        selectedOption={cuisine}
        setSelectedOption={setCuisine}
      />
    </div>
  );
};

export default Cuisine;
