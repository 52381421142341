import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";

import { useData } from "@/contexts/DataContext";

function getPercentage(decimal) {
  const percentage = decimal * 100;
  if (isNaN(percentage)) return "-";
  return percentage > 0
    ? `(+${percentage.toFixed(0)}%)`
    : `(${percentage.toFixed(0)}%)`;
}

const PriceBreakdown = () => {
  const data = useData();

  const order = useSelector((state) => state.orderSlice.order);
  const {
    kitchen: { pricing },
    cuisine,
    mealType,
    mealTime,
    period,
    quantity,
  } = order;

  const cuisineMultiplier =
    pricing.cuisineMultipliers.find((item) => item.cuisine === cuisine)
      .multiplier - 1;
  const mealTypeMultiplier =
    pricing.mealTypeMultipliers.find((item) => item.mealType === mealType)
      .multiplier - 1;
  const mealTimeMultiplier =
    pricing.mealTimeMultipliers.find((item) => item.mealTime === mealTime)
      .multiplier - 1;
  const periodMultiplier =
    pricing.periodMultipliers.find((item) => item.period === period)
      .multiplier - 1;

  const currencySymbol = pricing.currency === "INR" ? "₹" : pricing.currency;

  const basePrice = pricing.basePrice;
  const cuisinePrice = Math.round(basePrice * cuisineMultiplier);
  const mealTypePrice = Math.round(basePrice * mealTypeMultiplier);
  const mealTimePrice = Math.round(basePrice * mealTimeMultiplier);
  const periodPrice = Math.round(basePrice * periodMultiplier);

  const pricePerMeal =
    basePrice + cuisinePrice + mealTypePrice + mealTimePrice + periodPrice;

  const cuisinePercentage = getPercentage(cuisineMultiplier);
  const mealTypePercentage = getPercentage(mealTypeMultiplier);
  const mealTimePercentage = getPercentage(mealTimeMultiplier);
  const periodPercentage = getPercentage(periodMultiplier);

  const totalPeriodDays = data.periodToDaysMap[period];
  const totalPeriodPrice = Math.round(pricePerMeal * totalPeriodDays);

  const totalQuantity = quantity;
  const totalQuantityPrice = Math.round(totalPeriodPrice * totalQuantity);

  const gstRate = 0.05;
  const gstAmount = Math.round(totalQuantityPrice * gstRate);

  const formatCurrencyWithChangeSymbol = (value) => {
    const absoluteValue = Math.abs(value);
    if (isNaN(absoluteValue)) return "-";

    if (value === 0) return `${currencySymbol}${absoluteValue}`;
    return value < 0
      ? `-${currencySymbol}${absoluteValue}`
      : `+${currencySymbol}${absoluteValue}`;
  };

  const formatCurrency = (value) => {
    const absoluteValue = Math.abs(value);
    if (isNaN(absoluteValue)) return "-";
    return value < 0
      ? `-${currencySymbol}${absoluteValue}`
      : `${currencySymbol}${absoluteValue}`;
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Categories</TableCell>
            <TableCell>Details</TableCell>
            <TableCell>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Base Price</TableCell>
            <TableCell>{formatCurrency(basePrice)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Cuisine</TableCell>
            <TableCell>
              {data.cuisineLabels[cuisine]} {cuisinePercentage}
            </TableCell>
            <TableCell>
              {formatCurrencyWithChangeSymbol(cuisinePrice)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Meal Type</TableCell>
            <TableCell>
              {data.mealTypeLabels[mealType]} {mealTypePercentage}
            </TableCell>
            <TableCell>
              {formatCurrencyWithChangeSymbol(mealTypePrice)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Meal Time</TableCell>
            <TableCell>
              {data.mealTimeLabels[mealTime]} {mealTimePercentage}
            </TableCell>
            <TableCell>
              {formatCurrencyWithChangeSymbol(mealTimePrice)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Period</TableCell>
            <TableCell>
              {data.periodLabels[period]} {periodPercentage}
            </TableCell>
            <TableCell>{formatCurrencyWithChangeSymbol(periodPrice)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Price per meal</TableCell>
            <TableCell>{formatCurrency(pricePerMeal)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Days</TableCell>
            <TableCell>{data.periodToDaysMap[period]}</TableCell>
            <TableCell>{formatCurrency(totalPeriodPrice)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Quantity</TableCell>
            <TableCell>{totalQuantity}</TableCell>
            <TableCell>{formatCurrency(totalQuantityPrice)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>GST (5%)</TableCell>
            <TableCell></TableCell>
            <TableCell>{formatCurrency(gstAmount)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell style={{ fontWeight: 600 }}>Total Price</TableCell>
            <TableCell style={{ fontWeight: 600 }}>
              {formatCurrency(totalQuantityPrice + gstAmount)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PriceBreakdown;
