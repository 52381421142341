import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { LocateMeButton } from "@/components/Location";

import { AddressSearch } from "./components";

const SearchLocationPage = () => {
  const navigate = useNavigate();

  const submitPincodeHandler = (pincode) => {
    localStorage.setItem("pincode", pincode);
    navigate("/", { replace: true });
  };

  return (
    <Container>
      <AddressSearch />
      <LocateMeButton variant="outlined" submitPincode={submitPincodeHandler} />
    </Container>
  );
};

export default SearchLocationPage;
