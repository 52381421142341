export const orderStatusToMuiColor = {
  pending: "warning",
  active: "success",
  cancelled: "error",
  expired: "default",
  completed: "success",
};

export const expiryReasonMapping = {
  payment: "Payment timed out",
  miss: "Miss limit breach by kitchen",
  end: "Order End date crossed",
};

export const deliveryStatusToLabelMapping = {
  completed: "Delivery completed",
  skipped: "Skipped by you",
  missed: "Missed by kitchen",
  deliverable: "Upcoming delivery",
  nonDeliverable: "Non-deliverable date",
  dispatched: "Dispatched",
  invalid: "Invalid date",
  cancelled: "Cancelled",
  expired: "Expired",
};

export const deliveryStatusToColorMapping = {
  deliverable: "#2196f3",
  nonDeliverable: "#9c27b0",
  completed: "#4caf50",
  skipped: "#ffa726",
  missed: "#f44336",
  cancelled: "#795548",
  expired: "#9e9e9e",
  dispatched: "#00bcd4",
  invalid: "#ff5722",
};
