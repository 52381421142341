import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import AuthGuard from "./routeGuards/AuthGuard";
import DataGuard from "./routeGuards/DataGuard";
import routes from "./routes";
import { Loader } from "../loading";

const Router = () => {
  const renderedRoutes = routes.map((route) => {
    const { path, component, isProtected } = route;
    const componentWithDataGuard = <DataGuard>{component}</DataGuard>;

    return isProtected ? (
      <Route
        key={path}
        path={path}
        element={
          <Suspense fallback={<Loader />}>
            <AuthGuard>{componentWithDataGuard}</AuthGuard>
          </Suspense>
        }
      />
    ) : (
      <Route
        key={path}
        path={path}
        element={
          <Suspense fallback={<Loader />}>{componentWithDataGuard}</Suspense>
        }
      />
    );
  });

  const wildCardRoute = <Route path="*" element={<Navigate to="/" />} />;

  return (
    <Routes>
      {renderedRoutes}
      {wildCardRoute}
    </Routes>
  );
};

export default Router;
