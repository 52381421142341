import { useTheme } from "@emotion/react";

import { useAuth } from "@/contexts/AuthContext";
import handleError from "@/utils/handleError";

const useRazorpayPayment = () => {
  const theme = useTheme();
  const authCtx = useAuth();

  const invokePayment = async ({ order, onSuccess }) => {
    try {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        name: "Mealsign",
        order_id: order.payment.online.orderId,
        handler: function (response) {
          onSuccess({
            orderId: order._id,
            pgPaymentId: response.razorpay_payment_id,
            pgSignature: response.razorpay_signature,
          });
        },
        theme: {
          color: theme.palette.primary.main,
        },
        prefill: {
          name: authCtx.user.name,
          email: authCtx.user.email,
          contact: order.address.mobile,
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      handleError(error);
    }
  };

  return { invokePayment };
};

export default useRazorpayPayment;
