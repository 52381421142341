import axios from "axios";

let base = "/data/";

const get = async () => (await axios.get(base)).data;

const data = {
  get,
};

export default data;
