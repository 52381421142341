import axios from "axios";

import getAll from "@/utils/getAll";

let base = "/refunds/";

const getAllRefunds = getAll(base);

const getRefund = async ({ refundId }) =>
  (await axios.get(base + refundId)).data;

const refunds = {
  getAllRefunds,
  getRefund,
};

export default refunds;
