import { Typography } from "@mui/material";

const PriceDisplay = ({ price }) => {
  return (
    <div>
      <Typography gutterBottom>Price (incl. of all taxes)</Typography>
      <Typography variant="h5" color={"green"} fontWeight={"bold"}>
        ₹{price}
      </Typography>
    </div>
  );
};

export default PriceDisplay;
