import { Container } from "@mui/material";

import Header from "@/components/Header";

import { Auth } from "./components";

const AuthPage = () => {
  return (
    <>
      <Header hideRightSection />
      <Container style={{ paddingTop: "20px" }}>
        <Auth />
      </Container>
    </>
  );
};

export default AuthPage;
