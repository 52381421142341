import { lazy } from "react";

const AddressesPage = lazy(() => import("./pages/AddressesPage"));
const AddAddressPage = lazy(() => import("./pages/AddAddressPage"));
const EditAddressPage = lazy(() => import("./pages/EditAddressPage"));

const addressRoutes = [
  {
    path: "addresses",
    component: <AddressesPage />,
    isProtected: true,
  },
  {
    path: "addresses/add",
    component: <AddAddressPage />,
    isProtected: true,
  },
  {
    path: "addresses/:addressId/edit",
    component: <EditAddressPage />,
    isProtected: true,
  },
];

export default addressRoutes;
