import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ClearIcon from "@mui/icons-material/Clear";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { IconButton, TextField } from "@mui/material";
import { useFormik } from "formik";

const LocationPincodeInput = ({ submitPincode }) => {
  const formik = useFormik({
    initialValues: {
      pincode: "",
    },
    onSubmit: (values) => {
      submitPincode(values.pincode);
    },
  });

  const { pincode } = formik.values;

  const clearPincode = () => {
    formik.setFieldValue("pincode", "");
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const formattedValue = value.replace(/\D/g, "");
    formik.setFieldValue("pincode", formattedValue);
  };

  const startAdornment = (
    <IconButton size="small">
      <LocationOnIcon color={pincode.length === 6 ? "primary" : "action"} />
    </IconButton>
  );

  let endAdornment = null;

  if (pincode.length === 6) {
    endAdornment = (
      <IconButton size="small" type="submit">
        <ArrowForwardRoundedIcon color="primary" />
      </IconButton>
    );
  }

  if (pincode.length > 0 && pincode.length < 6) {
    endAdornment = (
      <IconButton size="small" onClick={clearPincode}>
        <ClearIcon color="action" />
      </IconButton>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Enter your delivery pincode"
        name="pincode"
        value={formik.values.pincode}
        onChange={handleChange}
        inputProps={{
          style: { textAlign: "center" },
          inputMode: "numeric",
          pattern: "[0-9]*",
          maxLength: 6,
        }}
        InputProps={{
          startAdornment,
          endAdornment,
        }}
      />
    </form>
  );
};

export default LocationPincodeInput;
