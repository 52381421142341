import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { AuthContextProvider } from "./contexts/AuthContext";
import { DataContextProvider } from "./contexts/DataContext";
import { setupAxios } from "./setup/axios";
import { Loader } from "./setup/loading";
import { Router } from "./setup/routing";
import { ThemeProvider } from "./setup/theme";
import { store } from "./store";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  setupAxios();
  return (
    <AuthContextProvider>
      <DataContextProvider>
        <Provider store={store}>
          <ThemeProvider>
            <BrowserRouter>
              <ToastContainer />
              <Loader />
              <Router />
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </DataContextProvider>
    </AuthContextProvider>
  );
};

export default App;
