import axios from "axios";

let base = "/ratings/";

const create = async ({ deliveryId, rating, review }) =>
  (await axios.post(base, { deliveryId, rating, review })).data;

const get = async ({ deliveryId }) =>
  (await axios.get(base + "ratingByDeliveryId/" + deliveryId)).data;

const ratings = {
  create,
  get,
};

export default ratings;
