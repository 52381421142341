import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Card, CardContent, Typography } from "@mui/material";

const AddressSuggestions = ({ getSuggestionItemProps, suggestions }) => {
  if (suggestions.length === 0) return null;
  return (
    <Card
      style={{
        position: "absolute",
        zIndex: 1,
        backgroundColor: "#fff",
        width: "100%",
      }}
    >
      <CardContent>
        {suggestions.map((suggestion, idx) => (
          <div
            {...getSuggestionItemProps(suggestion)}
            key={suggestion.placeId}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              padding: "10px 0px",
              borderBottom:
                idx === suggestions.length - 1 ? "none" : "1px solid #e0e0e0",
            }}
          >
            <LocationOnIcon color="action" style={{ marginRight: "10px" }} />
            <Typography variant="caption">{suggestion.description}</Typography>
          </div>
        ))}
      </CardContent>
    </Card>
  );
};

export default AddressSuggestions;
