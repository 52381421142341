import { useState } from "react";
import { Container } from "@mui/material";
import { useSelector } from "react-redux";

import BottomDrawer from "@/components/BottomDrawer";
import { useData } from "@/contexts/DataContext";

import DeliveryCalendar from "./DeliveryCalendar/DeliveryCalendar";
import generateOrderDates from "./utils/generateOrderDates";
import ViewButton from "../ViewButton";

const ViewCalendarButton = () => {
  const { dayToNumberMap, periodToDaysMap } = useData();
  const { kitchen, startDate, period } = useSelector(
    (state) => state.orderSlice.order,
  );
  const [open, setOpen] = useState(false);
  const openDrawer = () => setOpen(true);

  const isDataAvailable = Boolean(startDate && period);

  if (!isDataAvailable) {
    return null;
  }

  const { deliverableDates, endDate } = generateOrderDates({
    startDate,
    dayToNumberMap,
    periodToDaysMap,
    period,
    deliverableDays: kitchen.configuration.deliveryDays,
  });

  return (
    <>
      <ViewButton onClick={openDrawer}>Calendar</ViewButton>
      <BottomDrawer
        isOpen={open}
        setIsOpen={setOpen}
        title={"Delivery Calendar"}
      >
        <Container>
          <DeliveryCalendar
            startDate={startDate}
            endDate={endDate}
            deliverableDates={deliverableDates}
          />
        </Container>
      </BottomDrawer>
    </>
  );
};

export default ViewCalendarButton;
