import { Typography } from "@mui/material";

const ColonText = ({ first, second, color }) => {
  return (
    <Typography
      variant="body2"
      style={{ margin: "5px 0px" }}
      color={color && color}
    >
      <strong>{first} : </strong> {second}
    </Typography>
  );
};

export default ColonText;
