import { Navigate } from "react-router-dom";

import { useAuth } from "@/contexts/AuthContext";

const AuthGuard = ({ children }) => {
  const authCtx = useAuth();
  if (authCtx.isLoggedIn) {
    return children;
  } else {
    return <Navigate to="/auth" />;
  }
};

export default AuthGuard;
