import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LocationSearchInput = () => {
  const navigate = useNavigate();

  return (
    <TextField
      fullWidth
      variant="outlined"
      placeholder="Search your delivery location"
      onClick={() => navigate("/search-location")}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="action" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default LocationSearchInput;
