import { lazy } from "react";

const OrdersPage = lazy(() => import("./pages/OrdersPage"));
const OrderPage = lazy(() => import("./pages/OrderPage"));
const OrderUpdateAddressPage = lazy(
  () => import("./pages/OrderUpdateAddressPage"),
);
const DeliveryPage = lazy(() => import("./pages/DeliveryPage"));

const orderRoutes = [
  {
    path: "orders",
    component: <OrdersPage />,
    isProtected: true,
  },
  {
    path: "orders/:orderId",
    component: <OrderPage />,
    isProtected: true,
  },
  {
    path: "orders/:orderId/address",
    component: <OrderUpdateAddressPage />,
    isProtected: true,
  },
  {
    path: "orders/:orderId/delivery/:date",
    component: <DeliveryPage />,
    isProtected: true,
  },
];

export default orderRoutes;
