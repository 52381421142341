import Calendar from "react-calendar";

import "react-calendar/dist/Calendar.css";

import { deliveryStatusToColorMapping } from "@/utils/mappings";

import ColorLegend from "./ColorLegend";
import styles from "./DeliveryCalendar.module.css";

const DeliveryCalendar = ({ startDate, deliverableDates = [], endDate }) => {
  return (
    <div style={{ margin: "15px 0px" }}>
      <Calendar
        minDate={new Date(startDate)}
        maxDate={new Date(endDate)}
        tileClassName={({ date }) => {
          const isoDate = date.toISOString();
          if (new Date(isoDate) <= new Date(endDate)) {
            if (deliverableDates.includes(isoDate)) {
              return styles["deliverable-date"];
            } else {
              return styles["non-deliverable-date"];
            }
          }
          return null;
        }}
      />
      <ColorLegend
        legendItems={[
          {
            color: deliveryStatusToColorMapping.deliverable,
            description: "Deliverable date",
          },
          {
            color: deliveryStatusToColorMapping.nonDeliverable,
            description: "Non Deliverable date",
          },
        ]}
      />
    </div>
  );
};

export default DeliveryCalendar;
