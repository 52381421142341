import axios from "axios";

import { hideLoader, showLoader } from "@/setup/loading";

const api = axios.create({
  baseURL: "https://api.postalpincode.in/pincode/",
});

const getDetails = async ({ pincode }) => {
  try {
    showLoader();
    const { data } = await api.get(pincode);
    hideLoader();

    if (!data || data.length === 0) {
      return null;
    }
    const postOfficeList = data[0].PostOffice;
    if (!postOfficeList || postOfficeList.length === 0) {
      return null;
    }
    const postOffice = postOfficeList[0];

    return {
      district: postOffice.District,
      state: postOffice.State,
      country: postOffice.Country,
      pincode,
    };
  } catch (error) {
    hideLoader();
    throw error;
  }
};

const pincode = {
  getDetails,
};

export default pincode;
