import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import BottomDrawer from "../BottomDrawer";
import {
  LocateMeButton,
  LocationPincodeInput,
  LocationSearchInput,
} from "../Location";
import OrDivider from "../OrDivider";

const LocationDrawer = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();

  const submitPincodeHandler = (value) => {
    localStorage.setItem("pincode", value);
    navigate("/", { replace: true });
  };

  return (
    <BottomDrawer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={"Change Location"}
    >
      <Container style={{ paddingTop: "30px" }}>
        <div style={{ marginBottom: "10px" }}>
          <LocationSearchInput />
        </div>
        <LocateMeButton submitPincode={submitPincodeHandler} />
        <OrDivider />
        <LocationPincodeInput submitPincode={submitPincodeHandler} />
      </Container>
    </BottomDrawer>
  );
};

export default LocationDrawer;
