import axios from "axios";
import { toast } from "react-toastify";

import { hideLoader, showLoader } from "@/setup/loading";

const setupAxios = () => {
  axios.defaults.headers.common["Authorization"] =
    `Bearer ${localStorage.getItem("token")}`;

  axios.defaults.baseURL = `${process.env.REACT_APP_SERVER_URL}/api/v1`;

  let timer;
  let numOfCalls = 0;
  axios.interceptors.request.use(
    function (config) {
      numOfCalls++;
      if (timer) {
        clearTimeout(timer);
      }
      showLoader();
      timer = setTimeout(() => {
        hideLoader();
        toast.warn("Server is taking longer time than expected");
      }, 10000);
      return config;
    },
    function (error) {
      numOfCalls--;
      if (timer) {
        clearTimeout(timer);
      }
      hideLoader();
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    function (response) {
      numOfCalls--;
      if (timer) {
        clearTimeout(timer);
      }
      if (numOfCalls === 0) {
        hideLoader();
      }
      return response;
    },
    function (error) {
      numOfCalls--;
      if (timer) {
        clearTimeout(timer);
      }
      hideLoader();
      return Promise.reject(error);
    },
  );
};

export default setupAxios;
