import { useState } from "react";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";

import api from "@/api";

const ERROR_MESSAGES = {
  fetchLocation:
    "Error fetching location. Please enter your pincode to proceed.",
  locationDisabled:
    "Location is disabled. Please enable it in your browser settings.",
  locationDenied: "Location permission denied.",
  geolocationUnsupported:
    "Geolocation is not supported by this browser. Please enter your pincode to proceed.",
};

const LocateMeButton = ({ submitPincode, variant = "text" }) => {
  const [loading, setLoading] = useState(false);

  const handleGeolocationSuccess = async (position) => {
    try {
      const { latitude, longitude } = position.coords;

      const pincode = await api.googleMaps.geocodePincode({
        latitude,
        longitude,
      });

      setLoading(false);

      if (pincode) {
        submitPincode(pincode);
      } else {
        toast.error(ERROR_MESSAGES.fetchLocation);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error(ERROR_MESSAGES.fetchLocation);
    }
  };

  const handleGeolocationError = (error) => {
    console.error(error);
    if (error.code === error.PERMISSION_DENIED) {
      toast.error(ERROR_MESSAGES.locationDisabled);
    } else {
      toast.error(ERROR_MESSAGES.locationDenied);
    }
    setLoading(false);
  };

  const locateMe = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        handleGeolocationSuccess,
        handleGeolocationError,
      );
    } else {
      setLoading(false);
      toast.error(ERROR_MESSAGES.geolocationUnsupported);
    }
  };

  return (
    <Button
      fullWidth
      variant={variant}
      onClick={locateMe}
      disabled={loading}
      startIcon={loading ? <CircularProgress size={16} /> : <MyLocationIcon />}
    >
      <Typography>Locate Me using GPS</Typography>
    </Button>
  );
};

export default LocateMeButton;
