import axios from "axios";

import getAll from "@/utils/getAll";

let base = "/addresses/";

const create = async ({
  fullName,
  mobile,
  building,
  area,
  landmark,
  district,
  state,
  country,
  pincode,
  latitude,
  longitude,
}) =>
  (
    await axios.post(base, {
      fullName,
      mobile,
      building,
      area,
      landmark,
      district,
      state,
      country,
      pincode,
      latitude,
      longitude,
    })
  ).data;

const getAllAddress = getAll(base);

const get = async ({ addressId }) => (await axios.get(base + addressId)).data;

const update = async ({
  addressId,
  fullName,
  mobile,
  building,
  area,
  landmark,
  district,
  state,
  country,
  pincode,
  latitude,
  longitude,
}) =>
  (
    await axios.patch(base + addressId, {
      fullName,
      mobile,
      building,
      area,
      landmark,
      district,
      state,
      country,
      pincode,
      latitude,
      longitude,
    })
  ).data;

const remove = async ({ addressId }) =>
  (await axios.delete(base + addressId)).data;

const addresses = {
  create,
  getAll: getAllAddress,
  get,
  update,
  delete: remove,
};

export default addresses;
