import { createSlice } from "@reduxjs/toolkit";

const kitchensSlice = createSlice({
  name: "kitchens",
  initialState: {
    pincode: "",
    kitchens: [],
    kitchenSearchTerm: "",
    sortBy: "",
    cuisine: "",
    mealType: "",
    mealTime: "",
    period: "",
    filteredKitchens: [],
  },
  reducers: {
    setPincode: (state, action) => {
      state.pincode = action.payload;
    },
    setKitchens: (state, action) => {
      state.kitchens = action.payload;
    },
    setKitchenSearchTerm: (state, action) => {
      state.kitchenSearchTerm = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setCuisine: (state, action) => {
      state.cuisine = action.payload;
    },
    setMealType: (state, action) => {
      state.mealType = action.payload;
    },
    setMealTime: (state, action) => {
      state.mealTime = action.payload;
    },
    setPeriod: (state, action) => {
      state.period = action.payload;
    },
    setFilteredKitchens: (state, action) => {
      state.filteredKitchens = action.payload;
    },
  },
});

export const { actions: kitchensActions, reducer: kitchensReducer } =
  kitchensSlice;
