import { Typography } from "@mui/material";

const Title = ({ children, button, alignLeft }) => {
  let style = {
    margin: "15px 0px",
    textAlign: "center",
  };
  if (button) {
    style.display = "flex";
    style.justifyContent = "space-between";
    style.alignItems = "center";
    style.textAlign = "left";
  }
  if (alignLeft) {
    style.textAlign = "left";
  }

  return (
    <div style={style}>
      <Typography variant="h6">{children}</Typography>
      {button}
    </div>
  );
};

export default Title;
