import { Typography } from "@mui/material";

const IconText = ({ icon, text, variant, removeMargin }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        margin: !removeMargin && "5px 0px",
      }}
    >
      {icon}
      <Typography
        variant={variant || "body2"}
        style={{
          marginLeft: "8px",
        }}
      >
        {text}
      </Typography>
    </div>
  );
};

export default IconText;
