import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Typography } from "@mui/material";

const Location = ({ location }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      marginBottom: "10px",
    }}
  >
    <LocationOnIcon fontSize="small" />
    <Typography variant="body2">{location}</Typography>
  </div>
);

export default Location;
