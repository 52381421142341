import { useEffect, useRef } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SearchInput = ({ getInputProps, value, setValue }) => {
  const inputRef = useRef();
  const navigate = useNavigate();

  const clearValue = () => {
    setValue("");
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <TextField
      {...getInputProps({
        placeholder: "Enter area, street name...",
        variant: "outlined",
        fullWidth: true,
        InputProps: {
          startAdornment: (
            <IconButton onClick={() => navigate("/")} size="small" edge="start">
              <ArrowBackIosNewIcon />
            </IconButton>
          ),
          endAdornment: (
            <>
              {value ? (
                <IconButton onClick={clearValue} size="small" edge="end">
                  <ClearIcon />
                </IconButton>
              ) : (
                <IconButton size="small" edge="end">
                  <SearchIcon />
                </IconButton>
              )}
            </>
          ),
        },
      })}
      inputRef={inputRef}
    />
  );
};

export default SearchInput;
